export default {
  // Endpoints
  loginEndpoint: '/auth/Getlogin',
  recoverPasswordEndpoint: '/auth/recoverPassword',
  forgotEndpoint: '/auth/GetforgotPassword',
  registerEndpoint: '/auth/register',
  refreshEndpoint: '/auth/refresh-token',
  logoutEndpoint: '/auth/logout',
  verifyEndpoint: '/auth/verificarSenha',
  loginTomadorEndpoint: '/auth/GetloginTomador',
  getUserData: '/auth/GetUserData',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
