import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getGrupoAcesso, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from '@/router/routes/pages'
import dashboards from '@/router/routes/dashboards'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'adm' } },
    ...apps,
    ...dashboards,
    {
      path: '/adm',
      name: 'adm',
      component: () => import('@/views/adm.vue'),
    },
    {
      path: '/proposta',
      name: 'Proposta',
      component: () => import('@/views/proposta.vue'),
      meta: {
        pageTitle: 'Simulador de Proposta',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/contratos',
      name: 'Contratos',
      component: () => import('@/views/pages/financeiro/contratos/contratos.vue'),
      meta: {
        pageTitle: 'Contratos',
        requiresParametrizacao: true,
        breadcrumb: [
          {
            text: 'Contratos',
            to: { name: 'Contratos' },
          },
        ],
      },
    },
    {
      path: '/contratos/contrato/:id',
      name: 'Contrato',
      component: () => import('@/views/pages/financeiro/contratos/contratosContrato.vue'),
      props: true,
      meta: {
        pageTitle: 'Contrato',
        breadcrumb(route) {
          return [
            {
              text: 'Contratos',
              to: { name: 'Contratos' },
            },
            {
              text: `Contrato - ${route.query.codigo}`,
            },
          ]
        },
      },
    },

    {
      path: '/financeiro/assinatura',
      name: 'Financeiro Assinatura Tomador',
      component: () => import('@/views/pages/financeiro/contrato/assinaturas/ContratoConfirmarAssinatura.vue'),
      meta: {
        pageTitle: 'Assinatura de Contrato',
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/assinaturaDocumento',
      name: 'Assinatura de documento',
      component: () => import('@/views/pages/financeiro/contrato/assinaturas/AssinaturaDocumento.vue'),
      meta: {
        pageTitle: 'Assinatura de documento',
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/financeiro/verifica-assinatura/:token',
      name: 'Financeiro Assinatura Tomador',
      component: () => import('@/views/pages/financeiro/contrato/assinaturas/VerificaAssinatura.vue'),
      props: true,
      meta: {
        pageTitle: 'Assinatura de Contrato',
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/financeiro/contrato/:token',
      name: 'Financeiro Contrato',
      component: () => import('@/views/pages/financeiro/contrato/VisualizarContrato.vue'),
      meta: {
        pageTitle: 'Assinatura de Contrato',
        layout: 'full',
        resource: 'Auth',
      },
      props: true,
    },
    {
      path: '/pagamento',
      name: 'Pagamento',
      component: () => import('@/views/pages/financeiro/pagamento/pagamento.vue'),
      meta: {
        pageTitle: 'Pagamento',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/remessa-boleto',
      name: 'Boletos',
      component: () => import('@/views/pages/financeiro/remessa-boletos/remessa-boletos.vue'),
      meta: {
        pageTitle: 'Boletos',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/lancamento',
      name: 'Lancamentos',
      component: () => import('@/views/pages/financeiro/lancamento/lancamento.vue'),
      meta: {
        pageTitle: 'Lançamentos',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/grpe',
      name: 'GRPE',
      component: () => import('@/views/pages/financeiro/grpe/grpe.vue'),
      meta: {
        pageTitle: 'Arrecadação',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/produto',
      name: 'Produto',
      component: () => import('@/views/pages/Configuracoes/Produto/Produto.vue'),
      meta: {
        pageTitle: 'Configurações do Produto',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/configuracoes',
      name: 'ConfiguracoesSistema',
      component: () => import('@/views/pages/Configuracoes/Sistema/Sistema.vue'),
      meta: {
        pageTitle: 'Configurações do sistema',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/RelatorioCadastro',
      name: 'RelatorioCadastro',
      component: () => import('@/views/pages/relatorio/relatorioCad/relatorioCad.vue'),
      meta: {
        pageTitle: 'Relatórios de cadastro',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/RelatorioSimulador',
      name: 'RelatorioSimulador',
      component: () => import('@/views/pages/relatorio/relatorioSimuladorDePropostas/relatorioSimuladorDePropostas.vue'),
      meta: {
        pageTitle: 'Relatórios de simulações e propostas',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/RelatorioContrato',
      name: 'RelatorioContrato',
      component: () => import('@/views/pages/relatorio/relatorioContratos/relatorioContratoContratos.vue'),
      meta: {
        pageTitle: 'Relatórios de contratos',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/RelatorioFinanceiro',
      name: 'RelatorioFinanceiro',
      component: () => import('@/views/pages/relatorio/relatorioFinanceiro/relatorioFinanceiro.vue'),
      meta: {
        pageTitle: 'Relatórios financeiros',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/Dashboards',
      name: 'Dashboards',
      component: () => import('@/views/pages/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboards',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/ImportarExportar',
      name: 'ImportarExportar',
      component: () => import('@/views/pages/importarExportar/importarExportar.vue'),
      meta: {
        pageTitle: 'Importação/Exportação',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/dividas',
      name: 'RenegociacaoDividas',
      component: () => import('@/views/pages/renegociacao/dividas/dividas.vue'),
      meta: {
        pageTitle: 'Renegociação de Dívidas',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/antecipacaoParcelas',
      name: 'RenegociacaoAntecipacao',
      component: () => import('@/views/pages/renegociacao/antecipacaoParcelas/antecipacaoParcelas.vue'),
      meta: {
        pageTitle: 'Antecipação de Parcelas',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/workflow',
      name: 'ConfiguracoesWorkflor',
      component: () => import('@/views/pages/Configuracoes/Sistema/GrupoDeAcesso/GrupoDeAcesso.vue'),
      meta: {
        pageTitle: 'Configuração - Grupos de Acesso',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/modelos',
      name: 'ConfiguracoesModelos',
      component: () => import('@/views/pages/Configuracoes/Produto/ModeloDeDocumentos/ModeloDocumento.vue'),
      meta: {
        pageTitle: 'Configurações - Modelos de Documentos',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/LOG',
      name: 'GerenciaLOG',
      component: () => import('@/views/pages/Configuracoes/Sistema/GerenciamentoDeLog/GerenciamentoLog.vue'),
      meta: {
        pageTitle: 'Gerenciamento de LOG',
        requiresParametrizacao: true,
      },
    },
    {
      path: '/AssinaturaDigital',
      name: 'AssinaturaDigital',
      component: () => import('@/views/pages/Configuracoes/AssinaturaDigital.vue'),
      meta: {
        pageTitle: 'Assinatura Digital',
      },
    },
    {
      path: '/propostaExterna',
      name: 'PropostaExterna',
      component: () => import('@/views/pages/propostaExterna/propostaExterna.vue'),
      meta: {
        pageTitle: 'Empréstimo Consignado',
        layout: 'full',
        resource: 'Auth',
        requiresParametrizacao: true,
        redirectIfLoggedIn: false,
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/pages/auth/login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/esqueceu-senha',
      name: 'esqueci-a-senha',
      component: () => import('@/views/pages/auth/esqueceuSenha.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/autentica-senha',
      name: 'autentica-a-senha',
      component: () => import('@/views/pages/auth/autenticaSenha.vue'),
      meta: {
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/nao-encontrado.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/not',
      redirect: 'not-authorized',
    },
    {
      path: '/large',
      redirect: 'too-large',
    },
    {
      path: '/calculadoraEmprestimo',
      component: () => import('@/views/pages/calculadoraEmprestimo/calculadoraEmprestimo.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/termo',
      component: () => import('@/views/components/tabTermos/tomadorAceitarTermo/tomadorAceitarTermo.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  if (!isLoggedIn) {
    return next()
  }

  const userData = getUserData()
  if (userData?.role == 'master') {
    return next()
  }

  const result = []
  const permissoes = getGrupoAcesso()
  if (permissoes) {
    Object.entries(permissoes)?.flatMap((retorno) => {
      if (retorno[0] == to.name) {
        result.push(retorno)
      }
    })
  }

  if (result.length == 0) {
    return next() //se o resultado for 0, é que não existe a a parametrização da página
  }

  //se houver resultado do filtro
  if (result[0][0] == 'CadastroUnidadeGestora' || !result[0][1]) {
    return next({ name: 'not-authorized' })
  }

  return next() // Se tem a permissão vai pra próxima pag
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
