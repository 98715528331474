/**
 * Diretiva Vue.js para restringir a entrada do usuário em um campo de input e select.
 *
 * A diretiva permite apenas a entrada de caracteres que correspondam a um padrão regex fornecido e limita o tamanho do valor do input.
 * É útil para casos como campos de entrada numérica, máscaras de formatação, etc.
 *
 * Uso:
 *  <input v-bloqueioCaractereMask="{ tamanho: 5, regex: /[0-9]/ }">
 *  Este exemplo cria uma diretiva que aceita apenas números e limita o comprimento do input a 5 caracteres.
 *
 * @param {HTMLElement} element - O elemento DOM ao qual a diretiva está vinculada. Pode ser um elemento 'input' ou um contêiner que contenha um 'input'.
 * @param {Object} binding - Um objeto contendo as opções passadas para a diretiva. Deve ter as propriedades 'tamanho' e 'regex'.
 *    - tamanho (Number): O número máximo de caracteres permitidos no input.
 *    - regex (RegExp): Uma expressão regular para validar cada caractere digitado.
 *
 * Exemplo de Objeto binding:
 *  {
 *    tamanho: 5,
 *    regex: /[0-9]/
 *  }
 */
export default {
  inserted(element, binding) {
    const { tamanho, regex } = binding.value
    const inputElement =
      element.tagName === 'INPUT' || element.tagName === 'SELECT' ? element : element.querySelector('input, select')

    const filterInput = (event) => {
      const value = event.target.value
      const filteredValue = value.replace(regex, '').slice(0, tamanho)
      event.target.value = filteredValue

      setTimeout(() => {
        const filteredValue = value.replace(regex, '').slice(0, tamanho)
        event.target.value = filteredValue
      }, 1)
    }

    // Armazena a função no elemento para ser acessível no unbind
    element._filterInput = filterInput

    if (inputElement) {
      inputElement.addEventListener('input', filterInput)
    }
  },
  unbind(element) {
    const inputElement =
      element.tagName === 'INPUT' || element.tagName === 'SELECT' ? element : element.querySelector('input, select')

    if (inputElement && element._filterInput) {
      // Remove o event listener usando a função armazenada no elemento
      inputElement.removeEventListener('input', element._filterInput)
      // Limpa a referência da função para evitar vazamento de memória
      delete element._filterInput
    }
  },
}
